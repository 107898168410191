export function stripHtmlTags(str: string): string {
  return str.replace(/<\/?[^>]+(>|$)/g, "");
}

export function limitCharacters(str: string, limit: number): string {
  return str.length > limit - 3 ? str.substring(0, limit - 3) + "..." : str;
}

export function formatDescription(str: string, limit: number): string {
  return limitCharacters(stripHtmlTags(str ?? ""), limit);
}
