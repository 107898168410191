import { Injectable, Renderer2, RendererFactory2, inject } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { LanguageService } from "../core/services/language.service";
import { DOCUMENT } from "@angular/common";
import { formatDescription } from "../core/helpers/helpers";
import { REQUEST as SSR_REQUEST } from "ngx-cookie-service-ssr";
import { Request } from "express";
import { ScreenSizeService } from "@app/shared/screen-size.service";

@Injectable({
  providedIn: "root",
})
export class IndexabilityService {
  screenSizeService = inject(ScreenSizeService);

  private titleService = inject(Title);
  private metaService = inject(Meta);
  private languageService = inject(LanguageService);
  private document = inject<Document>(DOCUMENT);
  private request = inject<Request>(SSR_REQUEST, { optional: true });

  private renderer: Renderer2;

  constructor() {
    const rendererFactory = inject(RendererFactory2);
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setMetaTags(seo: any): void {
    const currentLang = this.languageService.currentLanguage();
    const title = seo.title;
    const description = formatDescription(seo.description, 160);
    const relativeUrl = seo.relativeUrl;

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: "description",
      content: description,
    });

    // Add meta tag for robots
    this.metaService.updateTag({ name: "robots", content: "index, follow" });

    // Add canonical link
    const canonicalLink = this.formatFullURL(relativeUrl, currentLang);
    // console.log(canonicalLink);
    const head = this.document.head;
    const existingCanonical = this.document.querySelector(
      `link[rel='canonical']`,
    );
    if (existingCanonical) {
      this.renderer.removeChild(head, existingCanonical);
    }
    const link = this.renderer.createElement("link");
    this.renderer.setAttribute(link, "rel", "canonical");
    this.renderer.setAttribute(link, "href", canonicalLink);
    this.renderer.appendChild(head, link);

    // Add hreflangs for supported site languages
    const langs = environment.supportedLanguages;

    langs.forEach((lang: string) => {
      const alternateLink = this.formatFullURL(relativeUrl, lang);
      const existingAlternate = this.document.querySelector(
        `link[hreflang='${lang}']`,
      );
      if (existingAlternate) {
        this.renderer.removeChild(head, existingAlternate);
      }
      const alternateTag = this.renderer.createElement("link");
      this.renderer.setAttribute(alternateTag, "rel", "alternate");
      this.renderer.setAttribute(alternateTag, "hreflang", lang);
      this.renderer.setAttribute(alternateTag, "href", alternateLink);
      this.renderer.appendChild(head, alternateTag);
    });

    // Add x-default
    const defaultLang = "en";
    const defaultLink = this.formatFullURL(relativeUrl, defaultLang);
    const existingDefault = this.document.querySelector(
      `link[hreflang='x-default']`,
    );
    if (existingDefault) {
      this.renderer.removeChild(head, existingDefault);
    }
    const defaultTag = this.renderer.createElement("link");
    this.renderer.setAttribute(defaultTag, "rel", "alternate");
    this.renderer.setAttribute(defaultTag, "hreflang", "x-default");
    this.renderer.setAttribute(defaultTag, "href", defaultLink);
    this.renderer.appendChild(head, defaultTag);
  }

  private formatFullURL(relativeUrl: string, lang: string): string {
    let baseUrl: string;

    if (this.screenSizeService.isBrowser) {
      baseUrl = location.origin;
    } else if (this.request) {
      // Server-side: extract from request
      const protocol = this.request.protocol;
      const host = this.request.get("host");
      baseUrl = `${protocol}://${host}`;
    } else {
      baseUrl = "";
    }

    return `${baseUrl}/${lang}/${relativeUrl}`;
  }
}
